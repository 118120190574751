import { Grid, Segment, Button, Divider } from 'semantic-ui-react';

import StargateLogo from '@spotahome/default-common/src/components/ui/StargateLogo';

const Main = () => (
  <Grid textAlign="center" style={{ marginTop: '60px' }} verticalAlign="middle">
    <Grid.Column style={{ maxWidth: 400 }}>
      <Segment raised padded>
        <StargateLogo centered size="small" />
        <Divider horizontal section>
          Log-in to your account
        </Divider>
        <Button
          color="google plus"
          icon="google"
          href={`/login/google`}
          content="Google"
          labelPosition="left"
        />
      </Segment>
    </Grid.Column>
  </Grid>
);

export default Main;
