import { getResourcePath } from '@spotahome/soyuz/client';
import { Image } from 'semantic-ui-react';

const StargateLogo = props => (
  <Image
    src={getResourcePath('/images/stargate.svg')}
    alt="spotahome"
    {...props}
  />
);

export default StargateLogo;
